import { useEffect, useState } from 'react';
import moment from "moment";
import { toast } from "react-toastify";

import { useFetch, useIsLoading } from '../../Hooks/useFetch';
import { useHistory } from "react-router-dom";

import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

import AedasLoading from "../../Components/Shared/AedasLoading/AedasLoading";
import CustomModal from '../../Components/Shared/ModalCustom/CustomModal';
import { getAllDomains, updateDomainById, deleteDomains } from "../../Service/InventoryService/InventoryService"; 

import { ListEditItems } from '../../Components/ListEditItems/ListEditItems';

import "../../Components/Static/Iventory/inventory.css";

export const Domains = () => {
  const [ domains, isFetchingDomains, updateDomains ] = useFetch(getAllDomains);
  const history = useHistory();

  const isLoading = useIsLoading([isFetchingDomains]);
  const [ openModal, setopenModal ] = useState(false);
  const [ selectedRow, setSelectedRow ] = useState<any>(null);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "url",
      headerName: "URL",
      width: 300,
      flex: 2,
      editable: false,
    },
    {
      field: "hosting",
      headerName: "Hosting",
      width: 300,
      flex: 1,
      editable: false,
    },
    {
      field: "emit",
      headerName: "Emisión",
      width: 300,
      flex: 1,
      renderCell(params) {
        if (params.value === "" || !params.value) {
          return <>Sin vencimiento</>;
        }
        return <>{moment(params.value).format("DD/MM/YYYY")}</>;
      },
      editable: false,
    },
    {
      field: "expiration_day",
      headerName: "Fecha de vencimiento",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{moment(params.value).format("DD/MM/YYYY")}</>;
      },
      editable: false,
    },
    {
      field: "cost_re",
      headerName: "Coste de renovación",
      width: 100,
      flex: 1,
      renderCell(params) {
        return <>{ params.value } €</>;
      },
      editable: false,
    },
    {
      field: "auto_re",
      headerName: "Autorenovación",
      width: 100,
      flex: 1,
      renderCell(params) {
        return <>{ params.value ? 'Si' : 'No'}</>;
      },
      editable: false,
    },
    {
      field: "advise_days",
      headerName: "Aviso",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{ `${params.value} días antes`}</>;
      },
      editable: false,
    },
    {
      field: "comments",
      headerName: "Comentarios",
      width: 300,
      flex: 2,
      editable: false,
    },
    {
      field: "button",
      headerName: "Acciones",
      width: 300,
      flex: 2,
      renderCell(params) {
        return ( 
          <div style={{display: "flex"}}>
            <Button
              onClick={() => {
                setSelectedRow(params.row);
                setopenModal(true)
              }}
              color="primary"
              className="reject_Button table-button"
              id={`edit-button-${params.row.id}`}
              key={`delete-${params.row.id}`}
            >
              borrar
            </Button>

            <Button
              onClick={ (e: any) => history.push(`/editar/dominio/${params.row.id}`)}
              color="primary"
              className="Button table-button"
              id={`edit-button-${params.row.id}`}
              key={params.row.id}
            >
              editar
            </Button>
          </div>
        )
      },
      editable: false,
    },
  ];

  const handleRemove = () => {
    if ( selectedRow ) {
      deleteDomains(selectedRow?.id)
         .then(() => toast.success("Borrado"))
         .then(() => updateDomains())
         .then(() => setopenModal(false))
    }
 }

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <div className='h-100 m-4'>
      <div className='main-container p-5' id='home'>
        <h4 className='main-title mb-3 mt-0'>Dominios </h4>

        <CustomModal
					size="lg"
					show={openModal}
					onChange={() => {
						setopenModal(!openModal);
					}}
					title="Confirmar borrado"
					body={
						<div>
							<div className="d-flex justify-content-center">
								<h5>Confirmo el borrado de este elemento</h5>
							</div>
							<div className="d-flex justify-content-center">
								<Button onClick={() => handleRemove()} className="reject_Button">
									Confirmar borrado
								</Button>
							</div>
						</div>
					}
				/>

        <ListEditItems 
          rows={domains} 
          columns={columns}
          serviceUpdate={updateDomainById}
          serviceDelete={deleteDomains}
          itemType={'dominio'}
          update={() => updateDomains()}
        />
  </div>
    </div>
  )
}
