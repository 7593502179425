import { useEffect, useState } from 'react';
import { Formik, useFormik } from "formik";

import { toast } from "react-toastify";
import * as yup from "yup";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";


import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useFetch, useIsLoading } from '../../Hooks/useFetch';

import AedasLoading from "../../Components/Shared/AedasLoading/AedasLoading";
import CustomModal from '../../Components/Shared/ModalCustom/CustomModal';

import { getAllAccessType } from "../../Service/AccessTypeService/AccessTypeService";
import { getAllHost } from "../../Service/HostService/HostService";
import { getAllParther } from "../../Service/PartherService/PartherService";
import { getAllLicenceTypeService } from "../../Service/LicenceTypeService/LicenceTypeService";
import {  addItemToInventory, 
					getInventoryItem, 
					updateInventory, 
          getInventoryFile,
					getCertificateItem,
					addItemToCertificates,
					updateCertificateById,
          getCertificateFile, 
					getDomainItem,
					addItemToDomains,
					updateDomainById,
          getDomainFile } from "../../Service/InventoryService/InventoryService";

const validationSchema = yup.object({
  app_name: yup.string().required("Este campo es requerido"),
  description: yup.string().required("Este campo es requerido"),
  id_access: yup.object().required("Este campo es requerido"),
});

export const NewItem = (props: any) => {
	const history = useHistory();
	const params: any = useParams();

	const newItemOptions = ['Licencia', 'Activo tecnológico', 'Dominio', 'Certificado'];
	const pathDict: any = {
		"licencia":{ 
			itemType: newItemOptions[0],
			getItemService: getInventoryItem,
      getFilesService: getInventoryFile, 
		},
    "activo":	{ 
			itemType: newItemOptions[1],
			getItemService: getInventoryItem,
      getFilesService: getInventoryFile,
		},
    "dominio":	{ 
			itemType: newItemOptions[2],
			getItemService: getDomainItem,
      getFilesService: getDomainFile,
		},
    "certificado":	{ 
			itemType: newItemOptions[3],
			getItemService: getCertificateItem,
      getFilesService: getCertificateFile,
		}
  }
	
	const path: string = window.location.pathname.split('/')[2];
	const itemType = pathDict[path]?.itemType;
	const itemServices = pathDict[path]?.getItemService || getInventoryItem;
	const filesServices = pathDict[path]?.getFilesService || getInventoryFile;

	const [ selectedRow, isFetchingInventaryItem ] = useFetch(itemServices, params);
  const [ attachmentDocs, isFetchingattachmentDocs ] = useFetch(filesServices, params);
  const [ access, isFetchingAccess ] = useFetch(getAllAccessType);
  const [ partner, isFetchingpartners ] = useFetch(getAllParther);
  const [ host, isFetchingHost ] = useFetch(getAllHost);
  const [ licence, isFetchingLicence ] = useFetch(getAllLicenceTypeService);

	const isLoading = useIsLoading([
		isFetchingInventaryItem,
    isFetchingattachmentDocs,
    isFetchingAccess,
		isFetchingpartners,
		isFetchingHost,
		isFetchingLicence
  ]);

  const [ files, setFiles ] = useState<any[]>([]);
  const [ filesName, setFilesNames ] = useState<any[]>([]);

  const [ IsLoadingButtom, setIsLoadingButtom ] = useState(false);

	const [ partnerOptions, setPartnerOptions ] = useState<any[]>([]);
	const [ makerOptions, setMakerOptions ] = useState<any[]>([]);
	
	const levelOptions = [
		{	
			id: 0,
			level: "Baja",
		},
		{	
			id: 1,
			level: "Moderada/Media",
		},
		{	
			id: 2,
			level: "Alta",
		},
	];
	const confidOptions = [
		{	
			id: 0,
			level: "Pública",
		},
		{	
			id: 1,
			level: "Interna",
		},
		{	
			id: 2,
			level: "Confidencial",
		},
		{	
			id: 3,
			level: "Reservada",
		},
		{	
			id: 4,
			level: "Secreta",
		}	
	];

	useEffect(() => {
		const sorter = (a: any, b: any) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0;

		if (partner) {
			setPartnerOptions(partner.filter((item: any) => item.is_part === true && item.is_part != null).sort(sorter));
			setMakerOptions(partner.filter((item: any) => item.is_maker === true && item.is_maker != null).sort(sorter));
		}
	}, [partner])

  const formik = useFormik({
    initialValues: {
			item_type: props.edit ? itemType : null,
      app_name: props.edit ? selectedRow?.app_name : null,
      description: props.edit ? selectedRow?.description : null,
      website: props.edit ? selectedRow?.website : null,
      id_access: props.edit ? selectedRow?.id_access && access ? access.find((a: any) => a.id === selectedRow?.id_access) : null : null,
			maker: props.edit ? selectedRow?.maker && partner ? partner.find((a: any) => a.id === selectedRow?.maker) : null : null,
			id_partner: props.edit ? selectedRow?.id_partner && partner ? partner.find((a: any) => a.id === selectedRow?.id_partner) : null : null,
			id_host: props.edit ? selectedRow?.id_host && host ? host.find((a: any) => a.id === selectedRow?.id_host) : null : null,
			id_licence: props.edit ? selectedRow ?.id_licence && licence ? licence.find((a: any) => a.id === selectedRow?.id_licence) : null : null,
      purchase_date: props.edit ? selectedRow?.purchase_date : null,
      accesses_number: props.edit ? selectedRow?.accesses_number : null,
      consumed_users: props.edit ? selectedRow?.consumed_users : null,
      licence_number: props.edit ? selectedRow?.licence_number : null,
      licenses_files: null,
			licenses_path: null,
      email: props.edit ? selectedRow?.email : null,
			status: props.edit ? selectedRow?.status : true,
			crits: props.edit ? selectedRow?.crits : 0,
			integrity: props.edit ? selectedRow?.integrity : 0,
			dispo: props.edit ? selectedRow?.dispo : 0,
			confid: props.edit ? selectedRow?.confid : 0,
			departmen: props.edit ? selectedRow?.departmen : null,
			product_own: props.edit ? selectedRow?.product_own : null,
			buss_own: props.edit ? selectedRow?.buss_own : null,
			advise_days: props.edit ? selectedRow?.advise_days : 0,
      budget: props.edit ? selectedRow?.budget : null,
      comments: props.edit ? selectedRow?.comments : null,
      supplier: props.edit ? selectedRow?.suppier : null,
      expiration_day: props.edit && selectedRow?.expiration_day ? moment(selectedRow?.expiration_day).format('YYYY-MM-DD') : null,
			type: props.edit ? selectedRow?.type : null,
			url: props.edit ? selectedRow?.url : null,
			hosting: props.edit ? selectedRow?.hosting : null,
			emit: props.edit && selectedRow?.emit ? moment(selectedRow?.emit).format('yyyy-MM-DD') : null,
			cost_re: props.edit ? selectedRow?.cost_re : null,
			auto_re: props.edit ? selectedRow?.auto_re : true,	
      active: true,
			files: null,
		},

    enableReinitialize: true,
    validationSchema: null,
    
		onSubmit: async (values: any) => {		
      
      const filesToSend = files.map((file64: string) => {
        return file64.replace("data:", "").replace(/^.+,/, "");
      });

      const fileObject = {
        name: filesName[0], 
        b64: filesToSend[0]
      }
      
      setIsLoadingButtom(true);
     
			const body = {
				item : {
					...values, 
					id: props.edit ? params?.id : null, 
					id_access: values.id_access?.id,
					id_host: values.id_host?.id,
					id_licence: values.id_licence?.id,
					id_partner: values.id_partner?.id,
					activo: values.item_type === 'Activo tecnológico' ? true : false,
					req_licence: values.item_type === 'Licencia' ? true : false,
					expiration_day: values.expiration_day ? values.expiration_day + 'T00:00' : null,
					emit: values.emit ? values.emit + 'T00:00' : null,
					crits: values.crits ? values.crits?.id : null,
					integrity: values.integrity ? values.integrity?.id : null,
					dispo: values.dispo ? values.dispo?.id : null,
					confid: values.confid ? values.confid?.id : null,
					maker: values.maker?.id,		
				}, 
				licenses_files: filesToSend,
				licenses_names: filesName,
        files: props.edit 
              ? filesToSend.length > 0 ? [...attachmentDocs, fileObject] : [...attachmentDocs] 
              : filesToSend.length > 0 ? [fileObject] : []
			}
     
			if ( values.item_type === 'Activo tecnológico' || values.item_type === 'Licencia' ) {
					if ( props.edit ) {
						updateInventory(body, body.item.id)
							.then(() => toast.success("Guardado"))
							.then(() => history.push(`${values.item_type === 'Activo tecnológico' ? '/activos-tecnologicos' : '/'}`))
					} else {
						addItemToInventory(body)
							.then(() => toast.success("Guardado"))
							.then(() => history.push(`${values.item_type === 'Activo tecnológico' ? '/activos-tecnologicos' : '/'}`)
							)
					}
			}

			if ( values.item_type === 'Dominio' ) {
				if ( props.edit ) {
					updateDomainById(body)
						.then(() => toast.success("Guardado"))
						.then(() => history.push('/dominios'))
				} else {
					addItemToDomains(body)
						.then(() => toast.success("Guardado"))
						.then(() => history.push('/dominios'))
				}
			}

			if ( values.item_type === 'Certificado' ) {
				if ( props.edit ) {
					updateCertificateById(body)
						.then(() => toast.success("Guardado"))
						.then(() => history.push('/certificados'))
				} else {
					addItemToCertificates(body)
						.then(() => toast.success("Guardado"))
						.then(() => history.push('/certificados'))
				}
			}
    },
  });
    
  const handleUploadFiles = (files: any, event: any) => {
    let filesUploadedList = event.currentTarget.files;

    let fileListBase64: any[] = [];
		let fileNameList: any[] = [];

    Object.values(filesUploadedList).forEach((file: any) => {
			fileNameList.push(file.name)

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileBase64 = reader.result?.toString() || "";
        fileListBase64.push(fileBase64);
      };
    });

    formik.handleChange(event);
		setFilesNames(fileNameList)
    setFiles(fileListBase64);
  };

	const handleActiveOptions = () => {
    	formik.setFieldValue('status', !formik.values.status);
  }

  const handleOpenFile = () => {
    if ( params.id ) {
      attachmentDocs.map((file: any, index: number) => {
        if ( attachmentDocs.length - 1 === index ) {      
          let pdfWindow = window.open("", `${file.name}`);
          pdfWindow?.document.write(
            `<iframe
                id=doc-${index}
                name='${file.name}'
                width='100%'
                height='100%'
                style='border:none'
                src='data:application/pdf;base64, ${encodeURI(file.b64)} '
            ></iframe>`
          )
        }
      })
    }
  }

	if (isLoading ) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading />
      </div>
    );
  }
  
  return (
		<>
		<div className='h-100 m-4'>
      <div className='main-container p-3' id='home'>
        <h4 className='main-title mb-3'>{ props.edit ? 'Editar' : 'Añadir' } {formik.values.item_type} </h4>

				<Box
					component="form"
					sx={{
						width: "90%",
						mx: "auto",
						my: 2,
						display: "flex",
						flexDirection: "column",
						alignItems: 'stretch'
					}}
					noValidate
					autoComplete="on"
					className="text-center"
					onSubmit={formik.handleSubmit}
					>
				{ props.edit ? <></> : (			
					<Autocomplete
						id="item_type"
						style={{ margin: "1rem 0", width: "50%"}}
						options={newItemOptions}
						onChange={(e, value) => {
							formik.setFieldValue("item_type", value);
						}}
						value={formik.values.item_type}
						renderInput={(params) => (
							<TextField
							{...params}
							label="Nuevo ítem"
								variant="outlined"
								error={
									formik.touched.item_type && Boolean(formik.errors.item_type)
								}
								helperText={formik.touched.item_type && formik.errors.item_type} 
							/>
						)}
					/>
				)}	

				{ formik.values.item_type === 'Activo tecnológico' || formik.values.item_type === 'Licencia' ? (
					<>					
						<div style={{display: "flex", justifyContent: "space-between"}}>
							<TextField
								required
								id="app_name"
								name="app_name"
								style={{ width: "50%", margin: "1rem 0", flexGrow: "2"}}
								label="Nombre"
								value={formik.values.app_name}
								onChange={formik.handleChange}
								error={formik.touched.app_name && Boolean(formik.errors.app_name)}
								helperText={formik.touched.app_name && formik.errors.app_name}
							/>

							<TextField 
								required
								id="description"
								name="description"
								style={{ width: "50%", margin: "1rem 0 1rem 1rem", flexGrow: "1"}}
								label="Descripción"
								value={formik.values.description}
								onChange={formik.handleChange}
								error={
									formik.touched.description && Boolean(formik.errors.description)
								}
								helperText={
									formik.touched.description && formik.errors.description
								}
							/>
						</div>

						<div style={{display: "flex", justifyContent: "space-between"}}>
							<TextField      
								id="website"
								name="website"
								label="Web"
								style={{ width: "35%", margin: "1rem 1rem 1rem 0", flexGrow: "2"}}
								value={formik.values.website}
								onChange={formik.handleChange}
								error={formik.touched.website && Boolean(formik.errors.website)}
								helperText={formik.touched.website && formik.errors.website}
							/>

							<Autocomplete
								id="id_access"
								options={access}
								style={{ margin: "1rem 0", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("id_access", value);
								}}
								getOptionLabel={(option: any) => option.name}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								value={formik.values.id_access}
								renderInput={(params) => (
									<TextField required {...params} label="Tipo acceso" />
								)}
							/>

							<Autocomplete
								id="id_host"
								options={host}
								style={{ margin: "1rem 0 1rem 1rem", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("id_host", value);
								}}
								getOptionLabel={(option: any) => option.name}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								value={formik.values.id_host}
								renderInput={(params) => (
									<TextField {...params} label="Alojamiento" />
								)}
							/>
						</div>

						<div style={{display: "flex", justifyContent: "space-between"}}>
							<Autocomplete
								id="id_partner"
								options={partnerOptions}
								style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("id_partner", value);
								}}
								getOptionLabel={(option: any) => option.name}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								value={formik.values.id_partner}
								renderInput={(params) => (
									<TextField {...params} label="Partner" />
								)}
							/>

							<Autocomplete
								id="maker"
								options={makerOptions}
								style={{ margin: "1rem 0", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("maker", value);
								}}
								getOptionLabel={(option: any) => option.name}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								value={formik.values.maker}
								renderInput={(params) => (
									<TextField {...params} label="Fabricante" />
								)}
							/>

							<TextField
								id="supplier"
								name="supplier"
								style={{ width: "35%", margin: "1rem 0 1rem 1rem"}}
								label="Número de proveedor"
								value={formik.values.supplier}
								onChange={formik.handleChange}
								error={
									formik.touched.supplier && Boolean(formik.errors.supplier)
								}
								helperText={formik.touched.supplier && formik.errors.supplier}
							/>             
						</div>
							
						<div style={{display: "flex", justifyContent: "space-between"}}>
						{ formik.values.item_type === 'Licencia' 
              ? (<>
                  <Autocomplete
                    id="id_licence"
                    options={licence}
                    style={{ margin: "1rem 1rem 1rem 0", width: "50%"}}
                    onChange={(e, value) => {
                      formik.setFieldValue("id_licence", value);
                    }}
                    getOptionLabel={(option: any) => option.name}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={formik.values.id_licence}
                    renderInput={(params) => (
                      <TextField {...params} label="Tipo de licencia" />
                    )}
                  />

                  <TextField
                    id="licence_number"
                    name="licence_number"
                    style={{ width: "50%", margin: "1rem 0"}}
                    label="Número de licencia"
                    value={formik.values.licence_number}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.licence_number &&
                      Boolean(formik.errors.licence_number)
                    }
                    helperText={
                      formik.touched.licence_number && formik.errors.licence_number
                    }
                  />   
                    
                  <div style={{ width: "50%", margin: "1rem 0 1rem 1rem"}} />        
                </> ) 
              : <></> }          
						</div>
						
						<div style={{display: "flex", justifyContent: "space-between"}}>
						
							<TextField
								type="number"
								id="accesses_number"
								name="accesses_number"
								style={{ width: "35%", margin: "1rem 1rem 1rem 0"}}
								InputProps={{
									inputProps: { min: 0 }
								}}
								label="Número de accesos"
								value={formik.values.accesses_number}
								onChange={formik.handleChange}
								error={
									formik.touched.accesses_number &&
									Boolean(formik.errors.accesses_number)
								}
								helperText={
									formik.touched.accesses_number && formik.errors.accesses_number
								}
							/>
							<TextField
								type="number"
								id="consumed_users"
								style={{ margin: "1rem 0", width: "35%"}}
								name="consumed_users"
								label="Accesos consumidos"
								value={formik.values.consumed_users}
								onChange={formik.handleChange}
								error={
									formik.touched.consumed_users &&
									Boolean(formik.errors.consumed_users)
								}
								helperText={
									formik.touched.consumed_users && formik.errors.consumed_users
								}
							/>   

							<Autocomplete
								id="confid"
								options={confidOptions}
								style={{ margin: "1rem 0 1rem 1rem", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("confid", value);
								}}
								getOptionLabel={(option: any) => option.level}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								// defaultValue={formik.values.confid}
								renderInput={(params) => (
									<TextField {...params} label="Confidencialidad" />
								)}
							/>         
						</div>
						
						<div style={{display: "flex", justifyContent: "space-between"}}>
							
							<TextField
								type="date"
								InputLabelProps={{ shrink: true }}
								style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
								id="purchase_date"
								name="purchase_date"
								label="Fecha de compra"
								value={formik.values.purchase_date}
								onChange={formik.handleChange}
								error={
									formik.touched.purchase_date &&
									Boolean(formik.errors.purchase_date)
								}
								helperText={
									formik.touched.purchase_date && formik.errors.purchase_date
								}
							/>

							<TextField
								id="budget"
								name="budget"
								style={{ margin: "1rem 0", width: "35%"}}
								label="Partida presupuestaria"
								value={formik.values.budget}
								onChange={formik.handleChange}
								error={formik.touched.budget && Boolean(formik.errors.budget)}
								helperText={formik.touched.budget && formik.errors.budget}
							/>
							
							<TextField
								type="email"
								id="email"
								name="email"
								style={{ width: "35%", margin: "1rem 0 1rem 1rem", flexGrow: "2"}}
								label="Email"
								value={formik.values.email}
								onChange={formik.handleChange}
								error={formik.touched.email && Boolean(formik.errors.email)}
								helperText={formik.touched.email && formik.errors.email}
							/>
						</div>

						<div style={{display: "flex", justifyContent: "space-between"}}>
							<Autocomplete
								id="crits"
								options={levelOptions}
								style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("crits", value);
								}}
								getOptionLabel={(option: any) => option.level}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								// value={formik.values.crits}
								renderInput={(params) => (
									<TextField {...params} label="Criticidad para negocio" />
								)}
							/>

							<Autocomplete
								id="integrity"
								options={levelOptions}
								style={{ margin: "1rem 0", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("integrity", value);
								}}
								getOptionLabel={(option: any) => option.level}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								// value={formik.values.integrity}
								renderInput={(params) => (
									<TextField {...params} label="Integridad" />
								)}
							/>

							<Autocomplete
								id="dispo"
								options={levelOptions}
								style={{ margin: "1rem 0 1rem 1rem", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("dispo", value);
								}}
								getOptionLabel={(option: any) => option.level}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								// value={formik.values.dispo}
								renderInput={(params) => (
									<TextField {...params} label="Disponibilidad" />
								)}
							/>
						</div>		

						<div style={{display: "flex", justifyContent: "space-between"}}>
							<TextField
									id="departmen"
									name="departmen"
									style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
									label="Departamento responsable de negocio"
									value={formik.values.departmen}
									onChange={formik.handleChange}
									error={formik.touched.departmen && Boolean(formik.errors.departmen)}
									helperText={formik.touched.departmen && formik.errors.departmen}
								/>

							<TextField
								id="product_own"
								name="product_own"
								style={{ margin: "1rem 0", width: "35%"}}
								label="Product Owner"
								value={formik.values.product_own}
								onChange={formik.handleChange}
								error={formik.touched.product_own && Boolean(formik.errors.product_own)}
								helperText={formik.touched.product_own && formik.errors.product_own}
							/>				

							<TextField
								id="buss_own"
								name="buss_own"
								style={{ margin: "1rem 0 1rem 1rem", width: "35%"}}
								label="Business Owner"
								value={formik.values.buss_own}
								onChange={formik.handleChange}
								error={formik.touched.buss_own && Boolean(formik.errors.buss_own)}
								helperText={formik.touched.buss_own && formik.errors.buss_own}
							/>
						</div>			

						
						{ formik.values.item_type === 'Licencia' ? (
							<FormControl id="status" style={{ minWidth: "15%", textAlign: "center", margin: "1rem 0 0"}}>
								<FormLabel style={{ fontSize: "0.8rem", textAlign:"start", color: "rgba(0, 0, 0, 0.6)" }}>Estado licencia</FormLabel>
								<FormGroup row onChange={formik.handleChange} style={{justifyContent: "start"}}>
									<FormControlLabel value={formik.values.status} control={<Checkbox id="is-active" checked={formik.values.status} onChange={handleActiveOptions}/>}  label="Activa" />
									<FormControlLabel value={!formik.values.status} control={<Checkbox id="is-inactive" checked={!formik.values.status} onChange={handleActiveOptions}/>}  label="Inactiva" />
								</FormGroup>
							</FormControl> 
						) : <></> }

					</>
					) : formik.values.item_type === 'Dominio' || formik.values.item_type === 'Certificado' ? (
						<>
							<div style={{display: "flex", justifyContent: "space-between"}}>				
								<TextField
									id="url"
									name="url"
									style={{ width: "30%", margin: "1rem 1rem 1rem 0", flexGrow: "2"}}
									label="URL"
									value={formik.values.url}
									onChange={formik.handleChange}
									error={formik.touched.url && Boolean(formik.errors.url)}
									helperText={formik.touched.url && formik.errors.url}
								/>	

								<TextField
									id="hosting"
									name="hosting"
									style={{ width: "30%", margin: "1rem 0", flexGrow: "2"}}
									label="Hosting"
									value={formik.values.hosting}
									onChange={formik.handleChange}
									error={formik.touched.hosting && Boolean(formik.errors.hosting)}
									helperText={formik.touched.hosting && formik.errors.hosting}
								/>	

								{ formik.values.item_type === 'Certificado' ? (
									<TextField
										id="type"
										name="type"
										style={{ width: "30%", margin: "1rem 0 1rem 1rem", flexGrow: "2"}}
										label="Tipo de certificado"
										value={formik.values.type}
										onChange={formik.handleChange}
										error={formik.touched.type && Boolean(formik.errors.type)}
										helperText={formik.touched.type && formik.errors.type}
									/>	
								) : <></> }

							</div>

							<div style={{display: "flex", justifyContent: "space-between"}}>
								{ formik.values.item_type === 'Dominio' ? (
									<>
										<TextField
											type="date"
											InputLabelProps={{ shrink: true }}
											style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
											id="emit"
											name="emit"
											label="Emisión"
											value={formik.values.emit}
											onChange={formik.handleChange}
											error={
												formik.touched.emit &&
												Boolean(formik.errors.emit)
											}
											helperText={
												formik.touched.emit && formik.errors.emit
											}
										/>

										<TextField
											id="cost_re"
											name="cost_re"
											type='number'
											style={{ width: "35%", margin: "1rem 1rem 1rem 0", flexGrow: "2"}}
											label="Coste renovación"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">€</InputAdornment>
												),
												inputProps: { min: 0 }
											}}
											value={formik.values.cost_re}
											onChange={formik.handleChange}
											error={formik.touched.cost_re && Boolean(formik.errors.cost_re)}
											helperText={formik.touched.cost_re && formik.errors.cost_re}
										/>
									</>
								) : <></> }	

								<FormControlLabel
									control={
										<Checkbox
											value={formik.values.auto_re}
											checked={formik.values.auto_re}
											onChange={formik.handleChange}
										/>
									}
									label="Autorenovación"
									id="auto_re"
									name="auto_re"
								/>	
							</div>		
						</> ) : <></>}	

					{ formik.values.item_type != null ? (
						<>
							<div style={{display: "flex", justifyContent: "space-between"}}>
								<TextField
									type="date"
									id="expiration_day"
									name="expiration_day"
									style={{ margin: "2rem 1rem 1rem 0", width: "35%"}}
									InputLabelProps={{ shrink: true }}
									label="Fecha de vencimiento"
									value={formik.values.expiration_day}
									onChange={formik.handleChange}
									error={
										formik.touched.expiration_day &&
										Boolean(formik.errors.expiration_day)
									}
									helperText={
										formik.touched.expiration_day && formik.errors.expiration_day
									}
								/>
								<TextField
									id="advise_days"
									name="advise_days"
									type='number'
									style={{ width: "35%", margin: "2rem 0 1rem"}}
									label="Días para aviso de caducidad"
									InputProps={{
										inputProps: { min: 0 }
									}}
									value={formik.values.advise_days}
									onChange={formik.handleChange}
									error={formik.touched.advise_days && Boolean(formik.errors.advise_days)}
									helperText={formik.touched.advise_days && formik.errors.advise_days}
								/>

                <TextField
                  id="licenses_files"
                  label={ formik.values.item_type === 'Licencia' ? "Contrato de suministro" : "Factura" }
                  style={{ width: "35%", margin: "2rem 0 1rem 1rem", flexGrow: "2"}}

                  inputProps={{
                    // multiple: true,
                    accept:"application/pdf",
                  }}
                  InputLabelProps={{ shrink: true }}
                  type={"file"}
                  onChange={(event: any) => {
                    handleUploadFiles(event.currentTarget.files[0], event);
                  }}
                  value={formik.values.licenses_files}
                  error={formik.touched.licenses_files && Boolean(formik.errors.licenses_files)}
                  helperText={formik.touched.licenses_files && formik.errors.licenses_files}
                />

                { props.edit && attachmentDocs.length > 0 ? 
                  <div style={{ width: "20%", margin: "0 -0.5rem 1rem 1rem", alignSelf: "center", justifySelf: "end" }}>
                    <Button className="Button table-button" onClick={handleOpenFile}> { itemType === 'Licencia' ? "Ver Contrato" : "ver Factura" } </Button>
                  </div>
                  : <></> } 
							</div>

							<TextField        
								multiline
								rows={3}
								id="comments"
								name="comments"
								style={{ margin: "1rem 0"}}

								label="Comentario"
								value={formik.values.comments}
								onChange={formik.handleChange}
								error={formik.touched.comments && Boolean(formik.errors.comments)}
								helperText={formik.touched.comments && formik.errors.comments}
							/>

							<div className="d-flex justify-content-center mt-3">							
								<LoadingButton
									className='Button'
									loading={IsLoadingButtom}
									style={{
										color: IsLoadingButtom ? "var(--blue-lis)" : "white",
										backgroundColor: "var(--blue-lis)",
									}}
									type="submit"
								>
									{!selectedRow ? "Crear" : "Guardar"}
								</LoadingButton>
							</div>
						</>
					) : <></> }
    		</Box>
			</div>
		</div>

		</>
  )
}
