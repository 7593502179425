import axios from "axios";
import Partner from "../../Components/Static/Partner/Partner";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";


export const addItemToInventory = (inventory: any) => {
  const body = {
    inventoryNew: inventory.item,
    files: inventory.files, 
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/inventory/",
    body,
    getDefaultConfigHeader()
  );
};

export const getAllInventory = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/inventory/",
    getDefaultConfigHeader()
  );
};

export const getInventoryItem = (params: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/inventory/" + params.id,
    getDefaultConfigHeader()
  );
};

export const updateInventory = (inventory: any, id: number) => {
  const body = {
    inventoryNew: inventory.item,
    files: inventory.files, 
  };

  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/inventory/" + id,
    body,
    getDefaultConfigHeader()
  );
};

export const deleteInventory = (inventory: any) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND + "/inventory/" + inventory.id,
    getDefaultConfigHeader()
  );
};

export const getInventoryFile = (inventory: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/inventory/files/" + inventory.id,
    getDefaultConfigHeader()
  );
};

export const getAllCertificates = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/certificate/",
    getDefaultConfigHeader()
  );
};

export const getCertificateItem = (params: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/certificate/" + params.id,
    getDefaultConfigHeader()
  );
};


export const addItemToCertificates = (certificate: any) => {
  const body = {
    certNew: certificate.item,
    files: certificate.files, 
  };
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/certificate/",
    body,
    getDefaultConfigHeader()
  );
};

export const updateCertificateById = (certificate: any) => {
  const body = {
    certNew: certificate.item,
    files: certificate.files, 
  };

  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/certificate/" + certificate.item.id,
    body,
    getDefaultConfigHeader()
  );
};

export const deleteCertificate = (id: number) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND + "/certificate/" + id,
    getDefaultConfigHeader()
  );
};

export const getCertificateFile = (certificate: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/certificate/files/" + certificate.id,
    getDefaultConfigHeader()
  );
};

export const getAllDomains = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/domain/",
    getDefaultConfigHeader()
  );
};

export const getDomainItem = (params: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/domain/" + params.id,
    getDefaultConfigHeader()
  );
};

export const addItemToDomains = (domain: any) => {
  const body = {
    domNew: domain.item,
    files: domain.files 
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/domain/",
    body,
    getDefaultConfigHeader()
  );
};

export const updateDomainById = (domain: any) => {
  const body = {
    domNew: domain.item,
    files: domain.files 
  };

  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/domain/" + domain.item.id,
    body,
    getDefaultConfigHeader()
  );
};

export const deleteDomains = (id: number) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND + "/domain/" + id,
    getDefaultConfigHeader()
  );
};

export const getDomainFile = (domain: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/domain/files/" + domain.id,
    getDefaultConfigHeader()
  );
};