/**
 * Obtiene la cabecera por defecto para enviar peticiones.
 *
 * @returns Object
 */
export const getDefaultConfigHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };
};
