import axios from "axios";
import Partner from "../../Components/Static/Partner/Partner";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";


export const getAllLicenceTypeService = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/licence_type/",
    getDefaultConfigHeader()
  );
};
